<template>
  <div class="tutor">
    <div class="header">
      <div class="center">
        {{ title }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="info-1">{{ text }}</div>
      <div class="img">
        <img :src="wechatUrl" alt="" />
      </div>
      <!-- <div class="btn">
                <button class="copy">
                    截图到微信扫码
                </button>
            </div> -->
      <div class="btn">
        <button class="copy" @click="onJumpMp">
          打开微信添加
        </button>
        <textarea v-model="wechatId" id="copy-textarea" />
      </div>
    </div>
    <!-- <div class="footer">
            <footerMenu></footerMenu>
        </div> -->
  </div>
</template>

<script>
import { Notify } from "vant";
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";
import { wechatInitApi } from "@/api/wechatInitApi.js";
import { isMobile, isWeiXin } from "@/util/browser.js";
export default {
  components: {
    // footerMenu,
  },
  data() {
    return {
      wechatId: "hepulaocai",
      wechatUrl: '',
      title: "添加老师～",
      text: "扫码领牛批的教资冲刺资料/咨询价格美丽的保过班",
    };
  },
  methods: {
    onJumpMp() {
      // this.$nextTick(() => {
      //   const text = document.getElementById("copy-textarea");
      //   text.select();
      //   if (document.execCommand("copy")) {
      //     document.execCommand("copy");
      //     Notify({
      //       type: "success",
      //       message: "已复制微信号:" + this.wechatId,
      //     });
      //   }
      // });
      let id = 2;
      if (this.$route.query && this.$route.query.id) {
        id = this.$route.query.id;
      }
      console.log("#");
      wechatInitApi.getOpenLink(id).then((ret) => {
        console.log(ret);
        let { code, data } = ret;
        if (code == 0) {
          location.href = data;
        }
      });
    },
    getQRCode(id) {
      if (!id) {
        return;
      }
      flexQrcodeApi.qrcode(id).then((ret) => {
        if (ret.code == 0 && ret.data) {
          console.log("..", ret);
          const item = ret.data;
          this.title = item.title;
          this.text = item.text;
          document.title = this.text;
          this.wechatId = item.weChat.wechatId;
          //this.wechatUrl = item.weChat.url;
          this.wechatUrl = item.currentUrlQrCodeBase64;
          console.log("##", this.wechatUrl);
        }
      });
    },
  },
  mounted() {
    const isWeiXinVal = isWeiXin();
    const isMobileVal = isMobile();
    let id = 2;
    if (this.$route.query && this.$route.query.id) {
      id = this.$route.query.id;
    }
    this.getQRCode(id);
    // weixin 打开才可以这么跳转，终究是错付了
    // const currentUrl = window.location.href;
    // wechatInitApi.getJsApiSignature(currentUrl).then((ret) => {
    //   let { code, data } = ret;
    //   if (code != 0) {
    //     return;
    //   }
    //   window.wx.config({
    //     debug: false, // 开启调试模式
    //     appId: data.appId, // 必填，公众号的唯一标识
    //     timestamp: data.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: data.nonceStr, // 必填，生成签名的随机串
    //     signature: data.signature, // 必填，签名，见附录1
    //     jsApiList: [], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     openTagList: ["wx-open-launch-weapp"],
    //   });
    //   window.wx.ready(function() {
    //     alert("...");
    //     // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中
    //     var btn = document.getElementById("launch-btn");
    //     btn.addEventListener("launch", function(e) {
    //       console.log("success");
    //     });
    //     btn.addEventListener("error", function(e) {
    //       console.log("fail", e.detail);
    //     });
    //   });
    //   window.wx.error(function(res) {
    //     // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
    //   });
    // });
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tutor {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 20px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    .info-1 {
      font-weight: 500;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      margin: 20px;
    }
    img {
      width: 220px;
      height: auto;
      margin: 0 0 20px 0;
    }
    .btn {
      display: flex;
      flex-direction: column;
      align-content: center;
      .copy {
        background: #ff8423;
        border-radius: 12px;
        width: 280px;
        height: 46px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0.44px;
        font-weight: 500;
      }
      #copy-textarea {
        position: absolute;
        left: -99999px;
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
